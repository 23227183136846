import React, { useEffect, useRef, useState } from 'react';
import { useCurrentRefinements, useSortBy } from 'react-instantsearch';

function CustomSortBy({ indexName }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { items } = useCurrentRefinements();
  const reports = [];
  const years = [];
  const volumes = [];

  if (items.length > 0) {
    items.forEach((item) => {
      if (item.attribute === 'report') {
        item.refinements.forEach((refinement) => {
          reports.push(refinement.value);
        });
      }
      if (item.attribute === 'report_year') {
        item.refinements.forEach((refinement) => {
          years.push(refinement.value);
        });
      }
      if (item.attribute === 'volume_num') {
        item.refinements.forEach((refinement) => {
          volumes.push(refinement.value);
        });
      }
    });
  }

  const hasSriLRorNLR = reports.some(
    (report) => report.includes('Sri-LR') || report.includes('NLR')
  );
  const hasSriLR = reports.some((report) => report.includes('Sri-LR'));
  const hasNLR = reports.some((report) => report.includes('NLR'));
  const hasYear = years.length > 0;

  const sortItems = [
    { label: 'Recent first', value: `${indexName}` },
    { label: 'Oldest first', value: `${indexName}/sort/date:asc` },
  ];

  if (hasSriLRorNLR) {
    sortItems.push(
      {
        label: 'Volume no. (ASC)',
        value: `${indexName}/sort/volume_num:asc,page_num:asc`,
      }
      // {
      //   label: 'Volume no. (DESC)',
      //   value: `${indexName}/sort/volume_num:desc,page_num:desc`,
      // },
      // {
      //   label: 'Page no. (ASC)',
      //   value: `${indexName}/sort/page_num:asc,volume_num:asc`,
      // },
      // {
      //   label: 'Page no. (DESC)',
      //   value: `${indexName}/sort/page_num:desc,volume_num:desc`,
      // }
    );
  }

  const { currentRefinement, options, refine } = useSortBy({
    items: sortItems,
  });

  console.log(currentRefinement);

  const isEmpty = sessionStorage.getItem('isEmpty');
  const defaultSortValue =
    (hasNLR || (hasSriLR && hasYear)) && isEmpty === 'true'
      ? `${indexName}/sort/volume_num:asc,page_num:asc`
      : `${indexName}`;

  const handleSelect = (value) => {
    refine(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isEmpty === 'true') {
      if (hasNLR || (hasSriLR && hasYear)) {
        refine(defaultSortValue);
      } else {
        refine(defaultSortValue);
      }
    } else {
      refine(defaultSortValue);
    }
  }, [refine, defaultSortValue, hasNLR, hasSriLR, isEmpty, hasYear]);

  const getIcon = (label, color) => {
    switch (label) {
      case 'Recent first':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="0.6"
          >
            <path d="M19 7H16L20 3L24 7H21V21H19V7ZM8 16H11V13H8V16ZM13 5H12V3H10V5H6V3H4V5H3C1.89 5 1 5.89 1 7V18C1 19.11 1.89 20 3 20H13C14.11 20 15 19.11 15 18V7C15 5.89 14.11 5 13 5ZM3 18V11H13V18H3Z" />
          </svg>
        );
      case 'Oldest first':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="0.6"
          >
            <path d="M21 17H24L20 21L16 17H19V3H21V17ZM8 16H11V13H8V16ZM13 5H12V3H10V5H6V3H4V5H3C1.89 5 1 5.89 1 7V18C1 19.11 1.89 20 3 20H13C14.11 20 15 19.11 15 18V7C15 5.89 14.11 5 13 5ZM3 18V11H13V18H3Z" />
          </svg>
        );
      case 'Page no. (ASC)':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            fillOpacity="0.7"
          >
            <g clipPath="url(#clip0_3_34)">
              <path d="M6 0C5.80109 0 5.61032 0.0790176 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V21.4425L2.7825 18.9675C2.64127 18.8263 2.44973 18.7469 2.25 18.7469C2.05028 18.7469 1.85873 18.8263 1.7175 18.9675C1.57627 19.1087 1.49693 19.3003 1.49693 19.5C1.49693 19.6997 1.57627 19.8913 1.7175 20.0325L5.4675 23.7825C5.53883 23.8508 5.62294 23.9043 5.715 23.94C5.80478 23.9797 5.90185 24.0002 6 24.0002C6.09815 24.0002 6.19523 23.9797 6.285 23.94C6.37773 23.9056 6.4621 23.852 6.5325 23.7825L10.2825 20.0325C10.4237 19.8913 10.5031 19.6997 10.5031 19.5C10.5031 19.3003 10.4237 19.1087 10.2825 18.9675C10.1413 18.8263 9.94973 18.7469 9.75 18.7469C9.55028 18.7469 9.35873 18.8263 9.2175 18.9675L6.75 21.4425V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790176 6.19891 0 6 0Z" />
              <path d="M18.7875 0.0599887C18.6548 0.00889084 18.5103 -0.00343359 18.3709 0.0244524C18.2315 0.0523384 18.1028 0.119286 18 0.217489L15.4725 2.72249C15.3876 2.86577 15.3529 3.03322 15.3737 3.19844C15.3945 3.36366 15.4697 3.51725 15.5875 3.635C15.7052 3.75275 15.8588 3.82796 16.0241 3.84878C16.1893 3.8696 16.3567 3.83484 16.5 3.74999L17.7225 2.52749V8.72249H17.25C17.0511 8.72249 16.8603 8.80151 16.7197 8.94216C16.579 9.08281 16.5 9.27358 16.5 9.47249C16.5 9.6714 16.579 9.86217 16.7197 10.0028C16.8603 10.1435 17.0511 10.2225 17.25 10.2225H19.7475C19.9464 10.2225 20.1372 10.1435 20.2778 10.0028C20.4185 9.86217 20.4975 9.6714 20.4975 9.47249C20.4975 9.27358 20.4185 9.08281 20.2778 8.94216C20.1372 8.80151 19.9464 8.72249 19.7475 8.72249H19.2525V0.749989C19.2518 0.60192 19.2072 0.457383 19.1245 0.334592C19.0417 0.211802 18.9245 0.116254 18.7875 0.0599887Z" />
              <path d="M18.5025 20.25C18.9109 20.2468 19.3153 20.168 19.695 20.0175C19.185 21.39 18.345 22.5 17.25 22.5C17.0511 22.5 16.8603 22.579 16.7197 22.7197C16.579 22.8603 16.5 23.0511 16.5 23.25C16.5 23.4489 16.579 23.6397 16.7197 23.7803C16.8603 23.921 17.0511 24 17.25 24C20.3175 24 21.75 19.8225 21.75 17.0025C21.7515 16.3581 21.5617 15.7277 21.2046 15.1913C20.8475 14.6549 20.3392 14.2365 19.7441 13.9892C19.149 13.7419 18.4939 13.6768 17.8618 13.8022C17.2297 13.9275 16.649 14.2377 16.1934 14.6934C15.7377 15.149 15.4275 15.7297 15.3022 16.3618C15.1768 16.9939 15.2419 17.649 15.4892 18.2441C15.7365 18.8392 16.1549 19.3474 16.6913 19.7045C17.2277 20.0616 17.8581 20.2515 18.5025 20.25ZM18.5025 15.2475C18.8496 15.2475 19.1889 15.3504 19.4775 15.5433C19.7661 15.7361 19.9911 16.0102 20.1239 16.3309C20.2567 16.6516 20.2915 17.0044 20.2238 17.3449C20.1561 17.6853 19.9889 17.998 19.7435 18.2435C19.498 18.4889 19.1853 18.656 18.8449 18.7238C18.5044 18.7915 18.1516 18.7567 17.8309 18.6239C17.5102 18.4911 17.2361 18.2661 17.0433 17.9775C16.8504 17.6889 16.7475 17.3496 16.7475 17.0025C16.7475 16.537 16.9324 16.0906 17.2615 15.7615C17.5907 15.4324 18.037 15.2475 18.5025 15.2475Z" />
            </g>
            <defs>
              <clipPath id="clip0_3_34">
                <rect width="24" height="24" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'Volume no. (DESC)':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="0.7"
          >
            <g clipPath="url(#clip0_3_42)">
              <path d="M7.4075 0.217544C7.3371 0.148092 7.25273 0.094405 7.16 0.0600443C6.9774 -0.0149692 6.7726 -0.0149692 6.59 0.0600443C6.49794 0.0957401 6.41383 0.149264 6.3425 0.217544L2.5925 3.96754C2.45127 4.10877 2.37193 4.30032 2.37193 4.50004C2.37193 4.69977 2.45127 4.89132 2.5925 5.03254C2.73373 5.17377 2.92527 5.25311 3.125 5.25311C3.32473 5.25311 3.51627 5.17377 3.6575 5.03254L6.125 2.55754V23.25C6.125 23.449 6.20402 23.6397 6.34467 23.7804C6.48532 23.921 6.67609 24 6.875 24C7.07391 24 7.26468 23.921 7.40533 23.7804C7.54598 23.6397 7.625 23.449 7.625 23.25V2.55754L10.0925 5.03254C10.1622 5.10284 10.2452 5.15864 10.3366 5.19671C10.428 5.23479 10.526 5.25439 10.625 5.25439C10.724 5.25439 10.822 5.23479 10.9134 5.19671C11.0048 5.15864 11.0878 5.10284 11.1575 5.03254C11.2278 4.96282 11.2836 4.87987 11.3217 4.78848C11.3597 4.69708 11.3793 4.59905 11.3793 4.50004C11.3793 4.40104 11.3597 4.30301 11.3217 4.21161C11.2836 4.12022 11.2278 4.03727 11.1575 3.96754L7.4075 0.217544Z" />
              <path d="M18.125 22.5C17.9261 22.5 17.7353 22.579 17.5947 22.7197C17.454 22.8604 17.375 23.0511 17.375 23.25C17.375 23.4489 17.454 23.6397 17.5947 23.7804C17.7353 23.921 17.9261 24 18.125 24H20.6225C20.8214 24 21.0122 23.921 21.1528 23.7804C21.2935 23.6397 21.3725 23.4489 21.3725 23.25C21.3725 23.0511 21.2935 22.8604 21.1528 22.7197C21.0122 22.579 20.8214 22.5 20.6225 22.5H20.1275V14.4975C20.1268 14.3495 20.0822 14.2049 19.9995 14.0821C19.9167 13.9593 19.7995 13.8638 19.6625 13.8075C19.5252 13.751 19.3742 13.7366 19.2287 13.7659C19.0832 13.7953 18.9496 13.8672 18.845 13.9725L16.3475 16.5C16.2078 16.6406 16.1294 16.8306 16.1294 17.0288C16.1294 17.2269 16.2078 17.417 16.3475 17.5575C16.488 17.6972 16.6781 17.7756 16.8762 17.7756C17.0744 17.7756 17.2645 17.6972 17.405 17.5575L18.6275 16.3425V22.5H18.125Z" />
              <path d="M19.3775 -3.40024e-05C18.9374 -0.0211776 18.4976 0.0472485 18.0847 0.201098C17.6719 0.354948 17.2945 0.591018 16.9756 0.895005C16.6566 1.19899 16.4027 1.56457 16.2292 1.96958C16.0558 2.37459 15.9663 2.81061 15.9663 3.25122C15.9663 3.69182 16.0558 4.12784 16.2292 4.53285C16.4027 4.93786 16.6566 5.30344 16.9756 5.60743C17.2945 5.91141 17.6719 6.14748 18.0847 6.30133C18.4976 6.45518 18.9374 6.52361 19.3775 6.50247C19.7859 6.49841 20.19 6.41961 20.57 6.26997C20.06 7.64247 19.22 8.75247 18.125 8.75247C17.9261 8.75247 17.7353 8.83148 17.5947 8.97214C17.454 9.11279 17.375 9.30355 17.375 9.50247C17.375 9.70138 17.454 9.89214 17.5947 10.0328C17.7353 10.1734 17.9261 10.2525 18.125 10.2525C21.1925 10.2525 22.625 6.07497 22.625 3.24747C22.625 2.38618 22.2829 1.56016 21.6738 0.951137C21.0648 0.342112 20.2388 -3.40024e-05 19.3775 -3.40024e-05ZM19.3775 5.00247C19.0301 5.00395 18.69 4.90229 18.4004 4.71035C18.1108 4.51841 17.8847 4.24484 17.7508 3.92429C17.6168 3.60374 17.5809 3.25063 17.6478 2.9097C17.7147 2.56877 17.8812 2.25536 18.1264 2.00917C18.3715 1.76298 18.6842 1.59508 19.0248 1.52676C19.3655 1.45843 19.7187 1.49274 20.0399 1.62535C20.361 1.75796 20.6355 1.9829 20.8287 2.27167C21.0219 2.56043 21.125 2.90004 21.125 3.24747C21.125 3.71162 20.9411 4.15688 20.6136 4.48579C20.2861 4.81471 19.8417 5.00048 19.3775 5.00247Z" />
            </g>
            <defs>
              <clipPath id="clip0_3_42">
                <rect width="24" height="24" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'Volume no. (ASC)':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            fillOpacity="0.7"
          >
            <g clipPath="url(#clip0_3_34)">
              <path d="M6 0C5.80109 0 5.61032 0.0790176 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V21.4425L2.7825 18.9675C2.64127 18.8263 2.44973 18.7469 2.25 18.7469C2.05028 18.7469 1.85873 18.8263 1.7175 18.9675C1.57627 19.1087 1.49693 19.3003 1.49693 19.5C1.49693 19.6997 1.57627 19.8913 1.7175 20.0325L5.4675 23.7825C5.53883 23.8508 5.62294 23.9043 5.715 23.94C5.80478 23.9797 5.90185 24.0002 6 24.0002C6.09815 24.0002 6.19523 23.9797 6.285 23.94C6.37773 23.9056 6.4621 23.852 6.5325 23.7825L10.2825 20.0325C10.4237 19.8913 10.5031 19.6997 10.5031 19.5C10.5031 19.3003 10.4237 19.1087 10.2825 18.9675C10.1413 18.8263 9.94973 18.7469 9.75 18.7469C9.55028 18.7469 9.35873 18.8263 9.2175 18.9675L6.75 21.4425V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790176 6.19891 0 6 0Z" />
              <path d="M18.7875 0.0599887C18.6548 0.00889084 18.5103 -0.00343359 18.3709 0.0244524C18.2315 0.0523384 18.1028 0.119286 18 0.217489L15.4725 2.72249C15.3876 2.86577 15.3529 3.03322 15.3737 3.19844C15.3945 3.36366 15.4697 3.51725 15.5875 3.635C15.7052 3.75275 15.8588 3.82796 16.0241 3.84878C16.1893 3.8696 16.3567 3.83484 16.5 3.74999L17.7225 2.52749V8.72249H17.25C17.0511 8.72249 16.8603 8.80151 16.7197 8.94216C16.579 9.08281 16.5 9.27358 16.5 9.47249C16.5 9.6714 16.579 9.86217 16.7197 10.0028C16.8603 10.1435 17.0511 10.2225 17.25 10.2225H19.7475C19.9464 10.2225 20.1372 10.1435 20.2778 10.0028C20.4185 9.86217 20.4975 9.6714 20.4975 9.47249C20.4975 9.27358 20.4185 9.08281 20.2778 8.94216C20.1372 8.80151 19.9464 8.72249 19.7475 8.72249H19.2525V0.749989C19.2518 0.60192 19.2072 0.457383 19.1245 0.334592C19.0417 0.211802 18.9245 0.116254 18.7875 0.0599887Z" />
              <path d="M18.5025 20.25C18.9109 20.2468 19.3153 20.168 19.695 20.0175C19.185 21.39 18.345 22.5 17.25 22.5C17.0511 22.5 16.8603 22.579 16.7197 22.7197C16.579 22.8603 16.5 23.0511 16.5 23.25C16.5 23.4489 16.579 23.6397 16.7197 23.7803C16.8603 23.921 17.0511 24 17.25 24C20.3175 24 21.75 19.8225 21.75 17.0025C21.7515 16.3581 21.5617 15.7277 21.2046 15.1913C20.8475 14.6549 20.3392 14.2365 19.7441 13.9892C19.149 13.7419 18.4939 13.6768 17.8618 13.8022C17.2297 13.9275 16.649 14.2377 16.1934 14.6934C15.7377 15.149 15.4275 15.7297 15.3022 16.3618C15.1768 16.9939 15.2419 17.649 15.4892 18.2441C15.7365 18.8392 16.1549 19.3474 16.6913 19.7045C17.2277 20.0616 17.8581 20.2515 18.5025 20.25ZM18.5025 15.2475C18.8496 15.2475 19.1889 15.3504 19.4775 15.5433C19.7661 15.7361 19.9911 16.0102 20.1239 16.3309C20.2567 16.6516 20.2915 17.0044 20.2238 17.3449C20.1561 17.6853 19.9889 17.998 19.7435 18.2435C19.498 18.4889 19.1853 18.656 18.8449 18.7238C18.5044 18.7915 18.1516 18.7567 17.8309 18.6239C17.5102 18.4911 17.2361 18.2661 17.0433 17.9775C16.8504 17.6889 16.7475 17.3496 16.7475 17.0025C16.7475 16.537 16.9324 16.0906 17.2615 15.7615C17.5907 15.4324 18.037 15.2475 18.5025 15.2475Z" />
            </g>
            <defs>
              <clipPath id="clip0_3_34">
                <rect width="24" height="24" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'Page no. (DESC)':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="0.7"
          >
            <g clipPath="url(#clip0_3_42)">
              <path d="M7.4075 0.217544C7.3371 0.148092 7.25273 0.094405 7.16 0.0600443C6.9774 -0.0149692 6.7726 -0.0149692 6.59 0.0600443C6.49794 0.0957401 6.41383 0.149264 6.3425 0.217544L2.5925 3.96754C2.45127 4.10877 2.37193 4.30032 2.37193 4.50004C2.37193 4.69977 2.45127 4.89132 2.5925 5.03254C2.73373 5.17377 2.92527 5.25311 3.125 5.25311C3.32473 5.25311 3.51627 5.17377 3.6575 5.03254L6.125 2.55754V23.25C6.125 23.449 6.20402 23.6397 6.34467 23.7804C6.48532 23.921 6.67609 24 6.875 24C7.07391 24 7.26468 23.921 7.40533 23.7804C7.54598 23.6397 7.625 23.449 7.625 23.25V2.55754L10.0925 5.03254C10.1622 5.10284 10.2452 5.15864 10.3366 5.19671C10.428 5.23479 10.526 5.25439 10.625 5.25439C10.724 5.25439 10.822 5.23479 10.9134 5.19671C11.0048 5.15864 11.0878 5.10284 11.1575 5.03254C11.2278 4.96282 11.2836 4.87987 11.3217 4.78848C11.3597 4.69708 11.3793 4.59905 11.3793 4.50004C11.3793 4.40104 11.3597 4.30301 11.3217 4.21161C11.2836 4.12022 11.2278 4.03727 11.1575 3.96754L7.4075 0.217544Z" />
              <path d="M18.125 22.5C17.9261 22.5 17.7353 22.579 17.5947 22.7197C17.454 22.8604 17.375 23.0511 17.375 23.25C17.375 23.4489 17.454 23.6397 17.5947 23.7804C17.7353 23.921 17.9261 24 18.125 24H20.6225C20.8214 24 21.0122 23.921 21.1528 23.7804C21.2935 23.6397 21.3725 23.4489 21.3725 23.25C21.3725 23.0511 21.2935 22.8604 21.1528 22.7197C21.0122 22.579 20.8214 22.5 20.6225 22.5H20.1275V14.4975C20.1268 14.3495 20.0822 14.2049 19.9995 14.0821C19.9167 13.9593 19.7995 13.8638 19.6625 13.8075C19.5252 13.751 19.3742 13.7366 19.2287 13.7659C19.0832 13.7953 18.9496 13.8672 18.845 13.9725L16.3475 16.5C16.2078 16.6406 16.1294 16.8306 16.1294 17.0288C16.1294 17.2269 16.2078 17.417 16.3475 17.5575C16.488 17.6972 16.6781 17.7756 16.8762 17.7756C17.0744 17.7756 17.2645 17.6972 17.405 17.5575L18.6275 16.3425V22.5H18.125Z" />
              <path d="M19.3775 -3.40024e-05C18.9374 -0.0211776 18.4976 0.0472485 18.0847 0.201098C17.6719 0.354948 17.2945 0.591018 16.9756 0.895005C16.6566 1.19899 16.4027 1.56457 16.2292 1.96958C16.0558 2.37459 15.9663 2.81061 15.9663 3.25122C15.9663 3.69182 16.0558 4.12784 16.2292 4.53285C16.4027 4.93786 16.6566 5.30344 16.9756 5.60743C17.2945 5.91141 17.6719 6.14748 18.0847 6.30133C18.4976 6.45518 18.9374 6.52361 19.3775 6.50247C19.7859 6.49841 20.19 6.41961 20.57 6.26997C20.06 7.64247 19.22 8.75247 18.125 8.75247C17.9261 8.75247 17.7353 8.83148 17.5947 8.97214C17.454 9.11279 17.375 9.30355 17.375 9.50247C17.375 9.70138 17.454 9.89214 17.5947 10.0328C17.7353 10.1734 17.9261 10.2525 18.125 10.2525C21.1925 10.2525 22.625 6.07497 22.625 3.24747C22.625 2.38618 22.2829 1.56016 21.6738 0.951137C21.0648 0.342112 20.2388 -3.40024e-05 19.3775 -3.40024e-05ZM19.3775 5.00247C19.0301 5.00395 18.69 4.90229 18.4004 4.71035C18.1108 4.51841 17.8847 4.24484 17.7508 3.92429C17.6168 3.60374 17.5809 3.25063 17.6478 2.9097C17.7147 2.56877 17.8812 2.25536 18.1264 2.00917C18.3715 1.76298 18.6842 1.59508 19.0248 1.52676C19.3655 1.45843 19.7187 1.49274 20.0399 1.62535C20.361 1.75796 20.6355 1.9829 20.8287 2.27167C21.0219 2.56043 21.125 2.90004 21.125 3.24747C21.125 3.71162 20.9411 4.15688 20.6136 4.48579C20.2861 4.81471 19.8417 5.00048 19.3775 5.00247Z" />
            </g>
            <defs>
              <clipPath id="clip0_3_42">
                <rect width="24" height="24" />
              </clipPath>
            </defs>
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative z-50 inline-block dark:text-pl_txt_dark_3 text-pl_txt_light_3 whitespace-nowrap">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center leading-tight"
      >
        <span className="ml-2">
          {options.find((o) => o.value === currentRefinement)?.label}
        </span>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 20 21"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="0.3"
          >
            <path d="M5.83331 8.40031L9.99998 12.567L14.1666 8.40031H5.83331Z" />
          </svg>
        </div>
      </button>

      {isOpen && (
        <div
          className="absolute right-0 text-14  gap-5 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden"
          ref={menuRef}
        >
          {options.map((option, index) => (
            <div key={option.value}>
              <button
                onClick={() => handleSelect(option.value)}
                className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
              >
                {getIcon(option.label, '#000000B2')}
                <span className="ml-2">{option.label}</span>
              </button>
              {index < options.length - 1 && (
                <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomSortBy;
