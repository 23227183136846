import { RefinementList, useCurrentRefinements } from 'react-instantsearch';
import React, { useState } from 'react';
import './RefinementListCard.css';
import {
  transformItemsCourt,
  transformItemsInt,
  transformItemsMonth,
} from '../../../utils/utils';
import { MenuSelect } from './MenuSelect/MenuSelect';

const RefinementListCard = ({ isMobile = false }) => {
  const { items } = useCurrentRefinements();
  const [expandedPanel, setExpandedPanel] = useState(0); // Track which panel is expanded

  const togglePanel = (index) => {
    setExpandedPanel(expandedPanel === index ? null : index);
  };

  const reports = [];

  if (items.length > 0) {
    items.forEach((item) => {
      if (item.attribute === 'report') {
        item.refinements.forEach((refinement) => {
          reports.push(refinement.value);
        });
      }
    });
  }

  const hasSriLR = reports.some((report) => report.includes('Sri-LR'));
  const hasNLR = reports.some((report) => report.includes('NLR'));

  // Define reusable `classNames` object
  const commonClassNames = {
    root: 'filter-search flex flex-col justify-center',
    noRefinementRoot: '',
    searchBox: 'flex max-w-[200px]',
    noResults: 'text-11 pl-3',
    item: 'flex flex-col mb-1',
    selectedItem: '',
    disabledShowMore: 'hidden',
    showMore: 'cursor-pointer text-11 text-left',
    list: 'list-none w-full px-0 mb-0',
    count:
      'bg-[#9d9d9d30] rounded-full text-10 font-normal px-2 ml-10 dark:bg-[#434343]',
    checkbox: 'ml-5 mr-2 flex-shrink-0',
    label: 'flex items-center text-13 mb-1 dark:text-pl_txt_dark_3',
    labelText: 'block truncate hover:whitespace-normal',
  };

  const filters = [
    {
      title: 'Filter by court',
      content: (
        <RefinementList
          attribute="sc_or_ca"
          searchable={false}
          searchablePlaceholder="Search court"
          showMore={true}
          classNames={commonClassNames}
          transformItems={transformItemsCourt}
        />
      ),
    },
    {
      title: 'Filter by report',
      content: (
        <RefinementList
          attribute="report"
          searchable={false}
          searchablePlaceholder="Search report"
          showMore={true}
          classNames={commonClassNames}
        />
      ),
    },
    ...(hasNLR
      ? [
          {
            title: 'Filter by volume',
            content: (
              <MenuSelect
                attribute={'volume_num'}
                transformItems={transformItemsInt}
                limit={1000}
              />
            ),
          },
          {
            title: 'Filter by page number',
            content: (
              <MenuSelect
                attribute={'page_num'}
                transformItems={transformItemsInt}
                limit={1000}
              />
            ),
          },
        ]
      : []),
    {
      title: hasSriLR ? 'Filter by report year' : 'Filter by year',
      content: (
        <RefinementList
          attribute={hasSriLR ? 'report_year' : 'decision_year'}
          searchable={true}
          searchablePlaceholder="Search year"
          showMore={true}
          sortBy={['name:desc']}
          classNames={commonClassNames}
        />
      ),
    },
    ...(hasSriLR && !(hasSriLR && hasNLR)
      ? [
          {
            title: 'Filter by volume',
            content: (
              <MenuSelect
                attribute={'volume_num'}
                transformItems={transformItemsInt}
                limit={1000}
              />
            ),
          },
          {
            title: 'Filter by page number',
            content: (
              <MenuSelect
                attribute={'page_num'}
                transformItems={transformItemsInt}
                limit={1000}
              />
            ),
          },
        ]
      : []),
    {
      title: 'Filter by judge',
      content: (
        <RefinementList
          attribute="judge_final"
          searchable={true}
          searchablePlaceholder="Search judge"
          showMore={true}
          classNames={commonClassNames}
        />
      ),
    },
    {
      title: 'Filter by month',
      content: (
        <RefinementList
          attribute="decision_month"
          searchable={false}
          searchablePlaceholder="Search month"
          showMore={true}
          limit={12}
          classNames={commonClassNames}
          transformItems={transformItemsMonth}
        />
      ),
    },
    {
      title: 'Filter by action',
      content: (
        <RefinementList
          attribute="action_type"
          searchable={false}
          searchablePlaceholder="Search action"
          showMore={true}
          classNames={commonClassNames}
        />
      ),
    },
  ];

  return (
    <div
      className={`pl-filter flex flex-col w-full dark:text-pl_txt_dark_3 text-pl_txt_light_3 h-fit ${
        isMobile
          ? ''
          : 'bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 overflow-hidden border dark:border-pl_border_dark_1 border-pl_border_light_1 rounded-lg'
      } `}
    >
      {filters.map((filter, index) => (
        <div
          key={index}
          className={`${
            index === filters.lastIndex ? 'border-none' : 'border-b'
          } dark:border-pl_border_dark_1 border-pl_border_light_1`}
        >
          <div
            onClick={() => togglePanel(index)}
            className="p-4 cursor-pointer flex justify-between items-center text-14 font-medium hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
          >
            {filter.title}
            <span>{expandedPanel === index ? '-' : '+'}</span>
          </div>

          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              expandedPanel === index ? 'max-h-[1000px]' : 'max-h-0'
            }`}
          >
            <div className="p-4">{filter.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RefinementListCard;
