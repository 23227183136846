import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@asgardeo/auth-react';
import { asgardeoConfig } from './services/asgardeo-config';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KDS52CWV',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider config={asgardeoConfig}>
        <App />
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);
